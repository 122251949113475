import '../styles/globals.css'
import 'react-datepicker/dist/react-datepicker.css'
import React, { useEffect } from 'react'
import App from 'next/app'
import type { AppProps, AppContext } from 'next/app'
import { useRouter } from 'next/router'
import { ChakraProvider, Box } from '@chakra-ui/react'
import basicAuthCheck from '../utils/basicAuthCheck'
import { AuthProvider } from '../context/auth'
import { OrderProvider } from '../context/order'
import { auth } from '../lib/firebase-client'
import { Sidebar } from '../components/Sidebar/Sidebar'

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter()
	const isOrders = router.pathname.match(/\/orders/)

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (!user) {
				router.push('/login')
			}
		})
	}, [auth])

	return (
		<AuthProvider auth={auth}>
			<ChakraProvider>
				{isOrders ? (
					<OrderProvider>
						<Sidebar />
						<Box pl="100px">
							<Box p={8}>
								<Component {...pageProps} />
							</Box>
						</Box>
					</OrderProvider>
				) : (
					<>
						<Sidebar />
						<Box pl="100px">
							<Box p={8}>
								<Component {...pageProps} />
							</Box>
						</Box>
					</>
				)}
			</ChakraProvider>
		</AuthProvider>
	)
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const { req, res } = appContext.ctx
	if (req && res && process.env.ENABLE_BASIC_AUTH === 'true') {
		await basicAuthCheck(req, res)
	}

	const appProps = await App.getInitialProps(appContext)
	return { ...appProps }
}

export default MyApp
