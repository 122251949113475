import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Flex, Stack, Spacer } from '@chakra-ui/react'
import {
	FaClipboardList,
	FaChartBar,
	FaUtensils,
	FaCalendarAlt,
	FaSignOutAlt,
	FaStore,
} from 'react-icons/fa'
import { NavLink } from './NavLink'
import { useAuth } from '../../context/auth'
import { useOrder } from '../../context/order'

export const Sidebar = () => {
	const router = useRouter()

	const [currentPath, setCurrentPath] = useState('')

	const { logout } = useAuth()
	const { orders } = useOrder()

	useEffect(() => {
		setCurrentPath(router.route)
	}, [router])

	const handleLogout = async () => {
		await logout()
		router.push('/login')
	}

	return (
		<Flex height="100vh" direction="column" bg="teal" color="white" py={8} position="fixed">
			<Stack spacing={6}>
				<Stack>
					<NavLink
						label={`注文受注`}
						icon={FaClipboardList}
						isActive={currentPath === '/orders' ? true : false}
						onClick={() => router.push('/orders')}
						badgeCount={orders && orders.filter((order) => order.status === 'ordered').length}
					/>
					<NavLink
						label={`売上明細`}
						icon={FaChartBar}
						isActive={currentPath === '/dashboard' ? true : false}
						onClick={() => router.push('/dashboard')}
					/>
					<NavLink
						label={`メニュー`}
						icon={FaUtensils}
						isActive={currentPath === '/settings/menu' ? true : false}
						onClick={() => router.push('/settings/menu')}
					/>
					<NavLink
						label={`営業時間`}
						icon={FaCalendarAlt}
						isActive={currentPath === '/settings/hours' ? true : false}
						onClick={() => router.push('/settings/hours')}
					/>
					<NavLink
						label={`店舗設定`}
						icon={FaStore}
						isActive={currentPath === '/settings/shop' ? true : false}
						onClick={() => router.push('/settings/shop')}
					/>
				</Stack>
				{/* <Divider borderColor="whiteAlpha.400" />
				<Stack>
					<NavLink label="ヘルプ" icon={FaRegQuestionCircle} />
				</Stack> */}
			</Stack>
			<Spacer />
			<NavLink label="ログアウト" icon={FaSignOutAlt} onClick={handleLogout} />
		</Flex>
	)
}
