import { VStack, Icon, Link, LinkProps, Text, Center } from '@chakra-ui/react'
import * as React from 'react'

interface NavLinkProps extends LinkProps {
	isActive?: boolean
	label: string
	icon: any
	badgeCount?: number
}

export const NavLink = (props: NavLinkProps) => {
	const { icon, isActive, label, badgeCount, ...rest } = props

	return (
		<Link
			display="block"
			px={3}
			py={4}
			transition="all 0.3s"
			fontWeight="medium"
			lineHeight="1.5rem"
			aria-current={isActive ? 'page' : undefined}
			color="whiteAlpha.900"
			_hover={{
				bg: 'teal.500',
				color: 'white',
			}}
			_activeLink={{
				bg: 'teal.700',
				color: 'white',
			}}
			{...rest}
		>
			<VStack spacing={4}>
				<Icon as={icon} boxSize="20px" />
				<Text>{label}</Text>
				{badgeCount && (
					<Center bgColor="red" position="absolute" top="10px" right="8px" w="24px">
						{badgeCount}
					</Center>
				)}
			</VStack>
		</Link>
	)
}
